import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

function App() {
  const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
    loaderUrl: "/unity/WebGL_Client.loader.js",
    dataUrl: "/unity/WebGL_Client.data.unityweb",
    frameworkUrl: "/unity/WebGL_Client.framework.js.unityweb",
    codeUrl: "/unity/WebGL_Client.wasm.unityweb",
  });

  return (
    <React.Fragment>
      {!isLoaded && (
        <p>Loading Application... {Math.round(loadingProgression * 100)}%</p>
      )}
      <Unity
        unityProvider={unityProvider}
        style={{
          width: 1400, 
          height: 900
         }}
      />
    </React.Fragment>
  );
}

export default App;